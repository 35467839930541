import { React } from "react";
import { Col, Row, Divider } from "antd";
import "./ServicesCard.css";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";

const vw = document.documentElement.clientWidth;
let mobileUser = false;
if (vw < 481) {
  mobileUser = true;
}

let mobileContent = mobileUser ? <div className="spacer">&nbsp;</div> : <></>;
let showDivider = mobileUser ? (
  <></>
) : (
  <Divider orientation="center" style={{ margin: "0%" }}></Divider>
);

const ServicesCard = () => (
  <div style={{ margin: "5%" }}>
    {mobileContent}
    <div className="site-layout-content-pitch-card">
      <div>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "2em",
                marginLeft: "24%",
                marginRight: "25%",
                marginBottom: "5%",
                border: "1px solid lightgrey",
                borderRadius: "10px",
              }}
            >
              Our <span style={{ color: "maroon" }}>Services </span>
            </h1>
            <Divider orientation="center" style={{ margin: "0%" }}></Divider>
            <ul style={{ margin: "4%", marginBottom: "4.2%" }}>
              <li>
                We supply highly experienced technical professionals to the
                State and Local government agencies of Texas. Our professionals
                fulfill roles in all facets of Information technology.
              </li>
              <li>
                We ensure the largest possible percentage of our client&#39;s
                expenditure flows through to the consultants.
              </li>
              <li>
                We strive to achieve the best value for your purchasing dollars
                in order to optimize the outcome and stability of your IT
                projects.
              </li>
              <li>
                We prioritize long-term relationships with our clients and
                professional staff. Avoid the turnover that can be detrimental
                to your budgets and timelines.
              </li>
            </ul>
          </Col>
        </Row>
        {showDivider}
        <Row
          justify="space-around"
          align="middle"
          style={{ paddingBottom: "5%", paddingLeft: "6%", marginTop: "-1%" }}
        >
          <Col xs={0} md={8} style={{ textAlign: "right" }}>
            <CommentOutlined style={{ margin: "0%", color: "indianred" }} />
            <span
              style={{
                margin: "0%",
                color: "indianred",
                paddingLeft: "5%",
              }}
            >
              Prompt & Professional
            </span>
          </Col>
          <Col xs={0} md={8} style={{ textAlign: "center", marginLeft: "-2%" }}>
            <DownCircleOutlined
              style={{ margin: "0%", color: "cornflowerblue" }}
            />
            <span
              style={{
                margin: "0%",
                color: "cornflowerblue",
                paddingLeft: "5%",
              }}
            >
              Low Margins
            </span>
          </Col>
          <Col xs={0} md={8} style={{ textAlign: "left", marginLeft: "-1%" }}>
            <UpCircleOutlined style={{ margin: "0%", color: "seagreen" }} />
            <span
              style={{ margin: "0%", color: "seagreen", paddingLeft: "5%" }}
            >
              Maximize Value
            </span>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

export default ServicesCard;
