import { Layout, Menu, Row, Col, Image } from "antd";
import { MenuOutlined, EllipsisOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import PageContent from "./components/page-content";
import "./App.css";
const backgroundImage = require(".//assets/Austin_Skyline2.jpg");
const logo = require(".//assets/ccs-header-banner-mobile.png");

const { Header, Footer } = Layout;

const App = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [leftMenuSelectedKeys, setLeftMenuSelectedKeys] = useState([]);
  const [weatherData, setWeatherData] = useState(null);
  const [weatherLoading, setWeatherLoading] = useState(false);

  if (weatherLoading) {
  }

  let mobileUser = false;
  let deskTopMenuItems = [
    { label: "Home", key: "home" },
    { label: "About Us / Contact", key: "about-us-contact" },
    { label: "DIR Staff/HUB Information", key: "dir-hub-info" },
  ];
  let mobileMenuItems = [
    { label: "Home", key: "home" },
    { label: "About Us / Contact", key: "about-us-contact" },
    { label: "DIR Staff/HUB Information", key: "dir-hub-info" },
    { label: "Services", key: "services" },
    { label: "Client List", key: "client-list" },
  ];

  useEffect(() => {}, [activeTab]);
  const vw = document.documentElement.clientWidth;

  let icon;
  let mobileOverflowIcon = (
    <MenuOutlined style={{ color: "white", background: "#990033" }} />
  );
  let desktopOverflowIcon = (
    <EllipsisOutlined style={{ color: "white", background: "#990033" }} />
  );

  if (vw < 481) {
    mobileUser = true;
  }

  let menuItems = mobileUser ? mobileMenuItems : deskTopMenuItems;

  vw > 991 ? (icon = desktopOverflowIcon) : (icon = mobileOverflowIcon);

  // TODO: this code is commented out to prevent unnessesary api calls while testing on localhost.

  useEffect(() => {
    fetch(
      "https://lc5thhcgfocfil3vd4oexry2qi0eggac.lambda-url.us-east-1.on.aws/"
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setWeatherData(data);
      })
      .catch((error) => {
        console.log("got error");
        console.log(error);
      })
      .finally(() => {
        setWeatherLoading(true);
      });
  }, []);

  return (
    <Layout className="layout">
      <Header
        style={{
          borderBottom: "1px solid white",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          paddingLeft: "15px",
          background: "#990033",
          width: "100%",
        }}
      >
        <Row style={{ height: "100%" }}>
          <Col span={1} lg={14} style={{ height: "100%" }}>
            <Menu
              triggerSubMenuAction="click"
              forceSubMenuRender={true}
              overflowedIndicator={icon}
              style={{
                marginRight: "0",
                height: "100%",
                background: "#990033",
              }}
              theme="dark"
              mode="horizontal"
              items={menuItems}
              onClick={(menuItem) => {
                setActiveTab(menuItem.key);
                setLeftMenuSelectedKeys([menuItem.key]);
              }}
              selectedKeys={leftMenuSelectedKeys}
            />
          </Col>
          <Col span={2} style={{ marginLeft: "8%" }}></Col>
          <Col xs={19} sm={0} md={0} lg={0} xl={0} xxl={0}>
            <Image
              preview={false}
              src={logo}
              width="112%"
              style={{ border: "1px solid #750000" }}
            />
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={8}
            xl={8}
            xxl={8}
            style={{ textAlign: "end", marginLeft: "-10%" }}
          >
            <Image
              preview={false}
              src={`https://openweathermap.org/img/wn/${weatherData?.weather[0].icon}.png`}
            />
            <span
              style={{
                color: "white",
                fontFamily: "proxima-nova, sans-serif",
              }}
            >
              {Math.round(weatherData?.main.temp)} {String.fromCharCode(176)}F
            </span>
            <span
              style={{
                color: "white",
                marginLeft: "15px",
                borderLeft: "1px solid white",
                padding: "5px 10px",
                fontFamily: "proxima-nova, sans-serif",
              }}
            >
              humidity: {weatherData?.main.humidity}%
            </span>
            <span
              style={{
                color: "white",
                padding: "5px 10px",
                borderLeft: "1px solid white",
                fontFamily: "proxima-nova, sans-serif",
              }}
            >
              wind: {weatherData?.wind.speed} mph
            </span>
          </Col>
        </Row>
      </Header>
      <Row
        style={{
          height: "100%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundAttachment: "fixed",
        }}
      >
        <Col style={{ height: "100%" }} span={24}>
          <PageContent tab={activeTab}></PageContent>
        </Col>
      </Row>
      <Row>
        <Col xs={0} sm={0} md={24}>
          <Footer
            style={{
              textAlign: "center",
              width: "100%",
              backgroundColor: "white",
              position: "fixed",
              bottom: 0,
            }}
          >
            <span>Capitol Consulting Services ©2022</span>
          </Footer>
        </Col>
      </Row>
    </Layout>
  );
};

export default App;
