import { React } from "react";
import { Col, Row, Image, Divider } from "antd";
import "./IntroCard.css";
let introPic = require("../../assets/connections.jpg");

const IntroCard = () => (
  <div>
    <Row>
      <Col xs={0} sm={4} style={{ textAlign: "center" }}>
        <Image style={{ width: "100%" }} src={introPic} preview={false}></Image>
      </Col>
      <Col xs={2} sm={0}></Col>
      <Col span={20}>
        <Row justify="space-around" align="middle">
          <Col xs={16} sm={0}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "2em",
                paddingLeft: "2%",
                marginBottom: "0px",
                border: "1px solid lightgrey",
                borderRadius: "10px",
              }}
            >
              Our <span style={{ color: "maroon" }}>Story</span>
            </h1>
          </Col>
          <Col xs={8} sm={0}>
            <Image
              style={{ width: "100%" }}
              src={introPic}
              preview={false}
            ></Image>
          </Col>
          <Col xs={0} sm={24}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "2em",
                marginBottom: "0px",
                marginLeft: "28%",
                marginRight: "42%",
                border: "1px solid lightgrey",
                borderRadius: "10px",
              }}
            >
              Our <span style={{ color: "maroon" }}>Story</span>
            </h1>
          </Col>
        </Row>
        <Col xs={24} sm={0}>
          <Divider
            style={{ margin: "0%", marginTop: "-1%", marginBottom: "1%" }}
          ></Divider>
        </Col>
        <p style={{ textAlign: "left" }}>
          Capitol Consulting Services (CCS) has provided quality consultants to
          State of Texas agencies concurrently since September 1997. We pride
          ourselves on acquiring senior level consultants that have a proven
          history of distinguished skills, ranging from project management,
          system analysis, web and mainframe development, system testing, and
          technical writing. We have built a solid reputation for meeting our
          client&#39;s needs, focusing resources as changing requirements
          develop, and consistently providing effective, successful solutions.
        </p>
        <p style={{ textAlign: "left", paddingTop: "0px" }}>
          Capitol Consulting Services not only searches out consultants via
          public medium, but incorporates our pool of existing consultants to
          identify quality personnel that have been professionally observed in
          the workplace. We have provided staffing to various agencies in the
          public and private sectors.
        </p>
        <p style={{ textAlign: "left", paddingTop: "0px" }}>
          Our foremost mission is to provide clients a high level of service at
          a reasonable price. CCS has experienced tremendous success that is
          grounded in our dual priority of keeping both clients and consultants
          happy. By maintaining a low overhead model, CCS is able to offer
          highly competitive billing rates to our clients and higher levels of
          quality and experienced personnel.
        </p>
      </Col>
    </Row>
  </div>
);

export default IntroCard;
