import { React } from "react";
import { Col, Row, Layout, Image, Divider } from "antd";
import "./AboutUsLargeCard.css";
const banner = require("../../assets/capitol-consulting-logo-drop-shadow.png");
let markPic = require("../../assets/Mark.jpg");
let leighPic = require("../../assets/Leigh.png");

const { Content } = Layout;

const vw = document.documentElement.clientWidth;

let mobileUser = false;
if (vw < 481) {
  mobileUser = true;
}

const AboutUsLargeCard = () => {
  if (mobileUser) {
    return AboutUsLargeCardMobile();
  } else {
    return (
      <div>
        <Content
          style={{
            padding: "0 5%",
            opacity: "97%",
          }}
        >
          <Row>
            <Col span={24}></Col>
          </Row>
          <Row>
            <Col xs={4} sm={0} style={{ marginBottom: "8%" }}></Col>
            <Col xs={0} sm={4}></Col>
            <Col xs={0} sm={16}>
              <Image
                style={{
                  marginLeft: "1%",
                  marginBottom: "2%",
                  marginTop: "1.5%",
                }}
                src={banner}
                preview={false}
              />
            </Col>
            <Col span={24} style={{ marginTop: "1.5%" }}>
              {" "}
            </Col>
          </Row>
          <div className="fadeIn">
            <div className="fadeIn">
              <div className="site-layout-content-about-us-card">
                <Row>
                  <Col xs={2} sm={0}></Col>
                  <Col xs={22} sm={24}>
                    <h1
                      style={{
                        textAlign: "center",
                        marginTop: "2%",
                        marginBottom: "0%",
                        fontSize: "2.2em",
                        marginRight: "8%",
                      }}
                    >
                      About Us
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Divider
                      orientation="right"
                      orientationMargin="10.5%"
                      style={{ marginTop: "1%" }}
                    >
                      {" "}
                      Contact
                    </Divider>
                  </Col>
                </Row>
                <Row justify="space-around" align="middle">
                  <Col xs={0} sm={4} style={{ textAlign: "center" }}>
                    <Image
                      style={{ width: "50%", borderRadius: "10px" }}
                      src={markPic}
                      preview={false}
                    ></Image>
                  </Col>
                  <Col span={14} style={{ borderRight: "1px solid lightgrey" }}>
                    <h1
                      style={{
                        textAlign: "center",
                        border: "1px solid lightgrey",
                        borderRadius: "10px",
                        marginLeft: "30%",
                        marginRight: "30%",
                      }}
                    >
                      Mark Ashley - <span style={{ color: "maroon" }}>CFO</span>
                    </h1>
                    <p>
                      Mark Ashley has been involved in a management, business
                      development, and customer service capacity since he was
                      initially employed by the State in 1982. He was an
                      employee at TXDOT for 8 years becoming the Financial
                      Systems Project Manager before his departure in 1989. He
                      has since been actively served as a consulting software
                      engineer for 32+ years with the Comptroller of Public
                      Accounts focusing on Statewide Financial Systems. In
                      addition, he served in business development for CW
                      Systems, Affiliated Computer Services, Inc., and Xerox.
                    </p>
                  </Col>
                  <Col span={6}>
                    <p style={{ textAlign: "center", margin: "0px" }}>
                      <strong style={{ color: "maroon" }}>Email:</strong>{" "}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      mark.ashley@capitolconsulting.com
                    </p>
                  </Col>
                </Row>
                <Divider orientation="left"></Divider>
                <Row
                  style={{ marginBottom: "5%", paddingBottom: "5%" }}
                  justify="space-around"
                  align="middle"
                >
                  <Col
                    xs={0}
                    sm={4}
                    style={{ textAlign: "center", marginTop: "2%" }}
                  >
                    <Image
                      style={{ width: "50%", borderRadius: "10px" }}
                      src={leighPic}
                      preview={false}
                    ></Image>
                  </Col>
                  <Col span={14} style={{ borderRight: "1px solid lightgrey" }}>
                    <h1
                      style={{
                        textAlign: "center",
                        border: "1px solid lightgrey",
                        borderRadius: "10px",
                        marginLeft: "30%",
                        marginRight: "30%",
                      }}
                    >
                      Leigh Ashley -{" "}
                      <span style={{ color: "maroon" }}>CEO</span>
                    </h1>
                    <p>
                      Leigh Ashley, the owner and principal of Capitol
                      Consulting Services since 1997, has been involved in
                      business management and customer support throughout her
                      career. Her tenure at Dell Computer Corporation and the
                      Information Technology Training Association strengthened
                      her diplomatic capacities as she worked closely with
                      competing representatives from software and hardware
                      developers such as Microsoft and Novell, and IT training
                      organizations such as Productivity Point International and
                      Resource Horizons. She oversees the account service and
                      management of Capitol Consulting Services clients and is
                      often the primary point of contact not only for CCS
                      customers, but many contractors as well.
                    </p>
                  </Col>
                  <Col span={6}>
                    <p style={{ textAlign: "center", margin: "0px" }}>
                      <strong style={{ color: "maroon" }}>Email:</strong>{" "}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      leigh.ashley@earthlink.net
                    </p>
                    <p style={{ textAlign: "center" }}></p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: "2%" }}></div>
        </Content>
      </div>
    );
  }
};

const AboutUsLargeCardMobile = () => (
  <div>
    <Content
      style={{
        padding: "0 5%",
        opacity: "97%",
      }}
    >
      <Row>
        <Col span={24}></Col>
      </Row>
      <Row>
        <Col xs={4} sm={0} style={{ marginBottom: "8%" }}></Col>
        <Col xs={0} sm={4}></Col>
        <Col xs={0} sm={16}>
          <Image
            style={{
              marginLeft: "1%",
              marginBottom: "2%",
              marginTop: "1.5%",
            }}
            src={banner}
            preview={false}
          />
        </Col>
        <Col span={24} style={{ marginTop: "1.5%" }}>
          {" "}
        </Col>
      </Row>
      <div className="site-layout-content-about-us-card">
        <Row>
          <Col xs={2} sm={0}></Col>
          <Col xs={22} sm={24}>
            <h1
              style={{
                textAlign: "center",
                marginTop: "2%",
                marginBottom: "0%",
                fontSize: "2.2em",
                marginRight: "8%",
              }}
            >
              About Us / Contact
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider
              orientation="center"
              orientationMargin="10.5%"
              style={{ marginTop: "1%" }}
            ></Divider>
          </Col>
          <Col span={24}>
            <h1
              style={{
                textAlign: "center",
                border: "1px solid lightgrey",
                borderRadius: "10px",
                marginLeft: "20%",
                marginRight: "20%",
                marginBottom: "5%",
              }}
            >
              Mark Ashley - <span style={{ color: "maroon" }}>CFO</span>
            </h1>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={8} style={{ textAlign: "center" }}>
            <Image
              style={{ width: "70%", borderRadius: "10px" }}
              src={markPic}
              preview={false}
            ></Image>
          </Col>
          <Col
            span={16}
            style={{
              height: "140px",
            }}
          >
            <p
              style={{
                textAlign: "left",
                padding: "0%",
                margin: "0%",
                marginTop: "2%",
              }}
            >
              <strong style={{ color: "maroon" }}>Email:</strong>
            </p>
            <p style={{ textAlign: "left", padding: "0%", margin: "0%" }}>
              mark.ashley@capitolconsulting.com
            </p>
          </Col>
          <Col span={24} style={{ borderRight: "1px solid lightgrey" }}>
            <p style={{ padding: "5%", marginBottom: "0%" }}>
              Mark Ashley has been involved in a management, business
              development, and customer service capacity since he was initially
              employed by the State in 1982. He was an employee at TXDOT for 8
              years becoming the Financial Systems Project Manager before his
              departure in 1989. He has since been actively served as a
              consulting software engineer for 32+ years with the Comptroller of
              Public Accounts focusing on Statewide Financial Systems. In
              addition, he served in business development for CW Systems,
              Affiliated Computer Services, Inc., and Xerox.
            </p>
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <Row
          style={{ marginBottom: "5%", paddingBottom: "5%" }}
          justify="space-around"
          align="middle"
        >
          <Col span={24}>
            <h1
              style={{
                textAlign: "center",
                border: "1px solid lightgrey",
                borderRadius: "10px",
                marginLeft: "20%",
                marginRight: "20%",
                marginBottom: "5%",
              }}
            >
              Leigh Ashley - <span style={{ color: "maroon" }}>CEO</span>
            </h1>
          </Col>
          <Col xs={8} style={{ textAlign: "center", marginTop: "2%" }}>
            <Image
              style={{ width: "70%", borderRadius: "10px" }}
              src={leighPic}
              preview={false}
            ></Image>
          </Col>
          <Col span={16} style={{ height: "140px" }}>
            <p
              style={{
                textAlign: "left",
                padding: "0%",
                margin: "0%",
                marginTop: "2%",
              }}
            >
              <strong style={{ color: "maroon" }}>Email:</strong>
            </p>
            <p style={{ textAlign: "left", padding: "0%", margin: "0%" }}>
              leigh.ashley@earthlink.net
            </p>
          </Col>
          <Col span={24} style={{ borderRight: "1px solid lightgrey" }}>
            <p style={{ padding: "5%" }}>
              Leigh Ashley, the owner and principal of Capitol Consulting
              Services since 1997, has been involved in business management and
              customer support throughout her career. Her tenure at Dell
              Computer Corporation and the Information Technology Training
              Association strengthened her diplomatic capacities as she worked
              closely with competing representatives from software and hardware
              developers such as Microsoft and Novell, and IT training
              organizations such as Productivity Point International and
              Resource Horizons. She oversees the account service and management
              of Capitol Consulting Services clients and is often the primary
              point of contact not only for CCS customers, but many contractors
              as well.
            </p>
          </Col>
        </Row>
      </div>
      <div style={{ paddingBottom: "2%" }}></div>
    </Content>
  </div>
);

export default AboutUsLargeCard;
