import { React } from "react";
import { Col, Row, Divider } from "antd";
import "./ClientListCard.css";

const vw = document.documentElement.clientWidth;
let mobileUser = false;
if (vw < 481) {
  mobileUser = true;
}

let mobileContent = mobileUser ? <div className="spacer">&nbsp;</div> : <></>;

const ClientListCard = () => (
  <div style={{ margin: "5%" }}>
    {mobileContent}
    <div className="site-layout-content-client-list-card">
      <div>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "2em",
                marginLeft: "30%",
                marginRight: "31%",
                marginBottom: "5%",
                border: "1px solid lightgrey",
                borderRadius: "10px",
              }}
            >
              Client <span style={{ color: "maroon" }}>List</span>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider
              orientation="left"
              style={{ marginTop: "0%", color: "maroon" }}
            >
              Private Sector Clients
            </Divider>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ width: "100%" }}>
            <ul
              style={{
                marginLeft: "5%",
                marginBottom: "0%",
                paddingBottom: "5%",
                paddingLeft: "14%",
              }}
            >
              <li> Texas Comptroller of Public Accounts</li>
              <li>Health and Human Services Commission</li>
              <li>Department of Human Services</li>
              <li>Texas Commission on Alcohol and Drug Abuse</li>
              <li> Attorney General</li>
              <li>Texas Education Agency</li>
              <li>Department of State Health Services</li>
              <li>Texas Department of Community Affairs </li>
            </ul>
          </Col>
          <Col span={12} style={{ width: "100%" }}>
            <ul
              style={{
                marginLeft: "5%",
                marginBottom: "0%",
                paddingBottom: "5%",
                paddingLeft: "9%",
              }}
            >
              <li>Texas Dept. of Health</li>
              <li>Texas Department of Transportation</li>
              <li>Texas Department of Transportation</li>
              <li>Department of Public Safety</li>
              <li>Texas Department of Aging and Disability Services</li>
              <li>Employees Retirement System</li>
              <li>Secretary of State</li>
              <li>Texas Govlink</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider
              orientation="left"
              style={{ marginTop: "3%", marginBottom: "-1%", color: "maroon" }}
            >
              Public Sector Clients
            </Divider>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ul style={{ marginLeft: "3%" }}>
              <li>Xerox</li>
              <li>Cherry Road</li>
              <li>NF Consulting </li>
              <li>Affiliated Computer Systems (ACS)</li>
              <li>Northgate Arinso</li>
              <li>Premier Consulting</li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

export default ClientListCard;
